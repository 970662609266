import {
  IAreaKey,
  ICard,
  IConsentsResolved,
  ILessonVisit,
  IPrepaidFormValues,
  IPromoBanner,
  IQuote,
  ISubscriptionCancelReason,
  ISurveyQuestionAnswer,
  ISurveyUserComment,
  IUser,
  IWrapper,
} from '@/types/main'
import {
  apiVersion,
  fetcher,
  fetchGetJSON,
  fetchPatchJSON,
  fetchPostJSON,
} from '@/utils/api-helpers'

/**
 * PrepaidCard
 */

export interface IValidatePrepaidCardArgs {
  prepaidCardCode: string
}
interface IValidatePrepaidCardResponse {
  valid: boolean
  reason?: string
}
export const validatePrepaidCard = async ({
  prepaidCardCode,
}: IValidatePrepaidCardArgs): Promise<IValidatePrepaidCardResponse> =>
  fetchPostJSON({
    uri: `/api/prepaid_cards/validate`,
    data: {
      code: prepaidCardCode,
    },
    authentication: 'required',
  })

/**
 * Voucher
 */

export interface IGetReferralCodeResponse {
  referral_code?: string
  error?: string
}
export const getReferralCode = async (): Promise<IGetReferralCodeResponse> =>
  fetcher({
    uri: `/api-auth/voucher/referral-cond`,
    authentication: 'required',
  })

export interface IGetSubscriptionVoucherResponse {
  name?: string
}
export const getSubscriptionVoucher =
  async (): Promise<IGetSubscriptionVoucherResponse> =>
    fetcher({
      uri: `/api-auth/voucher/subscription`,
      authentication: 'required',
    })

export interface IValidateSubscriptionVoucherArgs {
  voucherCode: string
}
export const validateSubscriptionVoucher = async ({
  voucherCode,
}: IValidateSubscriptionVoucherArgs): Promise<IGetSubscriptionVoucherResponse> =>
  fetchPostJSON({
    uri: `/api-auth/voucher/subscription-validate`,
    data: {
      voucherCode,
    },
    authentication: 'required',
  })

/**
 * Stripe related
 */

interface ICreateStripeSetupIntentResponse {
  client_secret: string
}
export const createStripeSetupIntent =
  async (): Promise<ICreateStripeSetupIntentResponse> =>
    fetchPostJSON({
      uri: '/api-auth/stripe/si',
      authentication: 'required',
    })

interface IRemoveStripePaymentMethodArgs {
  stripePaymentMethodId: string
}
export const removeStripePaymentMethod = async ({
  stripePaymentMethodId,
}: IRemoveStripePaymentMethodArgs): Promise<void> => {
  fetcher({
    uri: `/api-auth/stripe/pm/${stripePaymentMethodId}`,
    method: 'DELETE',
    authentication: 'required',
  })
}

interface ISetDefaultStripePaymentMethodArgs {
  stripePaymentMethodId: string
}
export const setDefaultStripePaymentMethod = async ({
  stripePaymentMethodId,
}: ISetDefaultStripePaymentMethodArgs): Promise<void> => {
  fetchPostJSON({
    uri: `/api-auth/stripe/pm/${stripePaymentMethodId}/default`,
    authentication: 'required',
  })
}

/**
 * Subscription
 */

export interface IGetSwitchSubscriptionOfferDetailsArgs {
  subscription: ICard['computedSubscription']
}
interface IGetSwitchSubscriptionOfferDetailsResponse {
  invoicePreview: {
    amountDue: number
    amountRemaining: number
    total: number
  }
  prorationDate: number
}
export const getSwitchSubscriptionOfferDetails = async ({
  subscription,
}: IGetSwitchSubscriptionOfferDetailsArgs): Promise<IGetSwitchSubscriptionOfferDetailsResponse> =>
  fetchPostJSON({
    uri: `/api/subscription/${subscription.ulid}/switch-offer`,
    authentication: 'required',
    data: {},
  })

export interface ISwitchSubscriptionOfferAcceptArgs {
  subscription: ICard['computedSubscription']
  prorationDate: number
}
interface ISwitchSubscriptionOfferAcceptResponse {}
export const switchSubscriptionOfferAccept = async ({
  subscription,
  prorationDate,
}: ISwitchSubscriptionOfferAcceptArgs): Promise<ISwitchSubscriptionOfferAcceptResponse> =>
  fetchPostJSON({
    uri: `/api/subscription/${subscription.ulid}/switch-offer-accept`,
    authentication: 'required',
    data: {
      prorationDate,
    },
  })

export interface ICancelSubscriptionArgs {
  subscription: ICard['computedSubscription']
  reasonCode: string
  reasonText?: string
}
interface IGetSubscriptionCancelReasonsResponse {
  reasonList: ISubscriptionCancelReason[]
}
export const getSubscriptionCancelReasons =
  async (): Promise<IGetSubscriptionCancelReasonsResponse> =>
    fetchGetJSON({
      uri: `/api/subscription-cancel-reasons`,
      authentication: 'required',
    })

interface IGetCancelSubscriptionOfferDetailsResponse {
  stripeCouponName: string
}
export const getCancelSubscriptionOfferDetails = async ({
  subscription,
  reasonCode,
  reasonText,
}: ICancelSubscriptionArgs): Promise<IGetCancelSubscriptionOfferDetailsResponse> =>
  fetchPostJSON({
    uri: `/api/subscription/${subscription.ulid}/cancel-offer`,
    authentication: 'required',
    data: {
      reasonCode,
      reasonText,
    },
  })

interface ICancelSubscriptionOfferAcceptResponse {
  stripePromoCode: {
    code: string
    expireAt: string
  }
}
export const cancelSubscriptionOfferAccept = async ({
  subscription,
  reasonCode,
  reasonText,
}: ICancelSubscriptionArgs): Promise<ICancelSubscriptionOfferAcceptResponse> =>
  fetchPostJSON({
    uri: `/api/subscription/${subscription.ulid}/cancel-offer-accept`,
    authentication: 'required',
    data: {
      reasonCode,
      reasonText,
    },
  })

interface ICancelSubscriptionResponse {}
export const cancelSubscription = async ({
  subscription,
  reasonCode,
  reasonText,
}: ICancelSubscriptionArgs): Promise<ICancelSubscriptionResponse> =>
  fetchPostJSON({
    uri: `/api/subscription/${subscription.ulid}/cancel`,
    data: {
      reasonCode,
      reasonText,
    },
    authentication: 'required',
  })

export interface IUndoCancelSubscriptionArgs {
  subscription: ICard['computedSubscription']
}
interface IUndoCancelSubscriptionResponse {}
export const undoCancelSubscription = async ({
  subscription,
}: IUndoCancelSubscriptionArgs): Promise<IUndoCancelSubscriptionResponse> =>
  fetchPostJSON({
    uri: `/api/subscription/${subscription.ulid}/cancel-undo`,
    authentication: 'required',
  })

/**
 * Visit
 */

export interface IBookVisitArgs {
  lessonIri: string
  guestEmail?: string
}
export const bookVisit = async ({
  lessonIri,
  guestEmail,
}: IBookVisitArgs): Promise<ILessonVisit> =>
  fetchPostJSON({
    uri: `/api/visits`,
    data: {
      lesson: lessonIri,
      guestEmail,
      platform: 'web',
    },
    authentication: 'required',
  })

export interface ICancelVisitsArgs {
  visits: ILessonVisit[]
}
export const cancelVisits = async ({ visits }: ICancelVisitsArgs): Promise<void> => {
  Promise.all(visits.map((visit) => cancelVisit({ visit })))
}

export interface ICancelVisitArgs {
  visit: ILessonVisit
}
export const cancelVisit = async ({ visit }: ICancelVisitArgs): Promise<void> => {
  fetcher({
    uri: `/api/visits/${visit.id}`,
    method: 'DELETE',
    authentication: 'required',
  })
}

/**
 * Favorite
 */

interface IFavoriteLessonArgs {
  lessonIri: string
}
export const favoriteLesson = async ({
  lessonIri,
}: IFavoriteLessonArgs): Promise<IWrapper<unknown>> =>
  fetchPostJSON({
    uri: `/api/favorites-lesson`,
    data: {
      lesson: lessonIri,
    },
    authentication: 'required',
  })

interface IFavoriteSerieArgs {
  serieIri: string
}
export const favoriteSerie = async ({
  serieIri,
}: IFavoriteSerieArgs): Promise<IWrapper<unknown>> =>
  fetchPostJSON({
    uri: `/api/favorites-serie`,
    data: {
      serie: serieIri,
    },
    authentication: 'required',
  })

interface IFavoriteTeacherArgs {
  teacherIri: string
}
export const favoriteTeacher = async ({
  teacherIri,
}: IFavoriteTeacherArgs): Promise<IWrapper<unknown>> =>
  fetchPostJSON({
    uri: `/api/favorites-teacher`,
    data: {
      teacher: teacherIri,
    },
    authentication: 'required',
  })

interface IFavoritePlaceArgs {
  placeIri: string
}
export const favoritePlace = async ({
  placeIri,
}: IFavoritePlaceArgs): Promise<IWrapper<unknown>> =>
  fetchPostJSON({
    uri: `/api/favorites-place`,
    data: {
      place: placeIri,
    },
    authentication: 'required',
  })

interface IUnFavoriteArgs {
  favoriteUuid: string
}
export const unfavorite = async ({ favoriteUuid }: IUnFavoriteArgs): Promise<void> => {
  fetcher({
    uri: `/api/favorites/${favoriteUuid}`,
    method: 'DELETE',
    authentication: 'required',
  })
}

interface IFavoriteVideoUserArgs {
  videoIri: string
}
export const favoriteVideo = async ({
  videoIri,
}: IFavoriteVideoUserArgs): Promise<void> => {
  fetchPostJSON({
    uri: `/api/video-user-favorite`,
    data: {
      video: videoIri,
    },
    authentication: 'required',
  })
}

export const unfavoriteVideo = async ({
  videoIri,
}: IFavoriteVideoUserArgs): Promise<void> => {
  fetchPostJSON({
    uri: `/api/video-user-unfavorite`,
    data: {
      video: videoIri,
    },
    authentication: 'required',
  })
}

interface IFavoriteVideoGroupUserArgs {
  videoGroupIri: string
}
export const favoriteVideoGroup = async ({
  videoGroupIri,
}: IFavoriteVideoGroupUserArgs): Promise<void> => {
  fetchPostJSON({
    uri: `/api/videogroup-user-favorite`,
    data: {
      videoGroup: videoGroupIri,
    },
    authentication: 'required',
  })
}

export const unfavoriteVideoGroup = async ({
  videoGroupIri,
}: IFavoriteVideoGroupUserArgs): Promise<void> => {
  fetchPostJSON({
    uri: `/api/videogroup-user-unfavorite`,
    data: {
      videoGroup: videoGroupIri,
    },
    authentication: 'required',
  })
}

/**
 * Quote
 */

export interface IQuoteInitArgs {
  priceUuid: string
  lessonUuid?: string
}
export const initQuote = async ({
  priceUuid,
  lessonUuid,
}: IQuoteInitArgs): Promise<IQuote> => {
  const priceIri = priceUuid && `/${apiVersion}/api/prices/${priceUuid}`
  const lessonIri = lessonUuid && `/${apiVersion}/api/lessons/${lessonUuid}`

  return fetchPostJSON({
    uri: `/api/quote`,
    data: {
      price: priceIri,
      ...(lessonIri && { lesson: lessonIri }),
      platform: 'web',
    },
    authentication: 'required',
  })
}

export interface IQuoteLoadArgs {
  quoteUuid: string
}
export const loadQuote = async ({ quoteUuid }: IQuoteLoadArgs): Promise<IQuote> =>
  fetchGetJSON({
    uri: `/api/quote/${quoteUuid}`,
    authentication: 'required',
  })

interface IQuoteFinalizeArgs {
  quoteUuid: string
  prepaidFormValues?: IPrepaidFormValues
  paymentMethodId?: string
}
interface IQuoteFinalizeResponse {
  status: 'confirmIntent' | 'paid'
  intent_type?: 'payment' | 'setup'
  client_secret?: string
}
export const finalizeQuote = async ({
  quoteUuid,
  prepaidFormValues,
  paymentMethodId,
}: IQuoteFinalizeArgs): Promise<IQuoteFinalizeResponse> =>
  fetchPostJSON({
    uri: `/api/quote/${quoteUuid}/finalize`,
    data: {
      prepaid: prepaidFormValues,
      paymentMethodId,
    },
    authentication: 'required',
  })

export interface IQuoteVoucherArgs {
  quoteUuid: string
  voucherCode: string
}
export const voucherQuote = async ({
  quoteUuid,
  voucherCode,
}: IQuoteVoucherArgs): Promise<IQuote> =>
  fetchPostJSON({
    uri: `/api/quote/${quoteUuid}/voucher`,
    data: {
      voucherCode,
    },
    authentication: 'required',
  })

/**
 * VideoUser
 */

interface IUpdateVideoUserPlaySessionArgs {
  videoIri: string
  trackingData: {
    startTimestamp: number
    totalTime: number
    currTime: number
    currPercent: number
  }
}
export const updateVideoPlaySession = async ({
  videoIri,
  trackingData,
}: IUpdateVideoUserPlaySessionArgs): Promise<void> => {
  fetchPostJSON({
    uri: `/api/video-user-play-session`,
    data: {
      video: videoIri,
      playSession: {
        st: trackingData.startTimestamp,
        tt: Math.round(trackingData.totalTime),
        ct: Math.round(trackingData.currTime),
        cp: trackingData.currPercent,
      },
    },
    authentication: 'required',
  })
}

/**
 * VisitPlay
 */

interface IUpdateVisitPlaySessionArgs {
  lessonIri: string
  trackingData: {
    startTimestamp: number
    totalTime: number
    currTime: number
    currPercent: number
  }
}
export const updateVisitPlaySession = async ({
  lessonIri,
  trackingData,
}: IUpdateVisitPlaySessionArgs): Promise<void> => {
  fetchPostJSON({
    uri: `/api/visit-play-session`,
    data: {
      lesson: lessonIri,
      playSession: {
        st: trackingData.startTimestamp,
        tt: Math.round(trackingData.totalTime),
        ct: Math.round(trackingData.currTime),
        cp: trackingData.currPercent,
      },
    },
    authentication: 'required',
  })
}

/**
 * User Companies
 */

export interface IUserCompanyJoinArgs {
  userUuid: string
  invitationCode: string
}
type IUserCompanyJoinResponse =
  | IUser
  | {
      detail?: string
    }
export const userCompanyJoin = async ({
  userUuid,
  invitationCode,
}: IUserCompanyJoinArgs): Promise<IUserCompanyJoinResponse> =>
  fetchPostJSON({
    uri: `/api/users/${userUuid}/company-join`,
    data: {
      invitationCode,
    },
    authentication: 'required',
  })

export interface IUserCompanyLeaveArgs {
  userUuid: string
  companyIri: string
}
type IUserCompanyLeaveResponse = IUser
export const userCompanyLeave = async ({
  userUuid,
  companyIri,
}: IUserCompanyLeaveArgs): Promise<IUserCompanyLeaveResponse> =>
  fetchPostJSON({
    uri: `/api/users/${userUuid}/company-leave`,
    data: {
      company: companyIri,
    },
    authentication: 'required',
  })

/**
 * PromoBanner
 */

export interface IGetPromoBannerSpotElectedArgs {
  spot: string
  areaKey?: IAreaKey
}
export type IGetPromoBannerSpotElectedResponse = { [spotSlot: string]: IPromoBanner }
export const getPromoBannerSpotElected = async ({
  spot,
  areaKey,
}: IGetPromoBannerSpotElectedArgs): Promise<IGetPromoBannerSpotElectedResponse> =>
  fetchPostJSON({
    uri: `/api/promo-banner-spots-elected`,
    data: {
      spot: `${spot}#`,
      platform: 'web',
      areaKey,
    },
    authentication: 'optional',
  })

/**
 * User Consents
 */

export interface IGetUserConsentsArgs {
  manageKey?: string
}
export type IGetUserConsentsResponse = {
  manageKey: string
  consentsResolved: IConsentsResolved[]
}
export const getUserConsents = async ({
  manageKey,
}: IGetUserConsentsArgs): Promise<IGetUserConsentsResponse> =>
  fetchGetJSON({
    uri: `/api/user-consents/${manageKey ?? 'current'}`,
    authentication: 'optional',
  })

export interface IUpdateUserConsentsArgs {
  manageKey: string
  consentsResolved: Pick<IConsentsResolved, 'id' | 'key' | 'status'>[]
}
export const updateUserConsents = async ({
  manageKey,
  consentsResolved,
}: IUpdateUserConsentsArgs): Promise<IGetUserConsentsResponse> =>
  fetchPatchJSON({
    uri: `/api/user-consents/${manageKey}`,
    data: { consentsResolved },
    authentication: 'optional',
  })

/**
 * SurveyUser
 */

export interface IGetSurveyUserArgs {
  id: string
}
export type IGetSurveyUserResponse = {
  id: string
  surveyName: string
  questionsAnswers: ISurveyQuestionAnswer[]
  lessonUuid?: string
  videoUuid?: string
  videoGroupUuid?: string
}
export const getSurveyUser = async ({
  id,
}: IGetSurveyUserArgs): Promise<IGetSurveyUserResponse> =>
  fetchGetJSON({
    uri: `/api/survey-user/${id}`,
    authentication: 'required',
  })

export interface IUpdateSurveyUserArgs {
  id: string
  answers: { [questionUuid: string]: any }[]
  _private: boolean
}
export const updateSurveyUser = async ({
  id,
  answers,
  _private,
}: IUpdateSurveyUserArgs): Promise<IGetSurveyUserResponse> =>
  fetchPatchJSON({
    uri: `/api/survey-user/${id}`,
    data: { answers, private: _private },
    authentication: 'required',
  })

/**
 * SurveyUserComment
 */

export interface IGetSurveyUserCommentsArgs {
  serieUuid?: string
  teacherId?: number
  placeId?: number
  placePostalCode?: number
  lessonArea?: string
}
export type IGetSurveyUserCommentsResponse = ISurveyUserComment[]
export const getSurveyUserComments = async (
  params: IGetSurveyUserCommentsArgs
): Promise<IGetSurveyUserCommentsResponse> =>
  fetchGetJSON({
    uri: `/api/survey-user-comment`,
    params: {
      serie_uuid: params.serieUuid,
      teacher_id: params.teacherId,
      place_id: params.placeId,
      place_postalcode: params.placePostalCode,
      lesson_area: params.lessonArea,
    },
    authentication: 'optional',
  })
