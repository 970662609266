import React from 'react'
import styled from 'styled-components'

import { Container, IContainerProps } from './Container'

interface IProps {
  message?: string
  scope?: IContainerProps['scope']
  className?: string
}

export const ContainerMessage: React.FC<IProps> = ({
  message = '',
  scope,
  className,
}) => (
  <Container scope={scope} className={className}>
    <MessageText>{message}</MessageText>
  </Container>
)

export const ContainerLoading: React.FC<IProps> = ({
  message = 'Chargement...',
  scope,
}) => <ContainerMessage message={message} scope={scope} />
export const ContainerNotFound: React.FC<IProps> = ({
  message = 'Non trouvé',
  scope,
}) => <ContainerMessage message={message} scope={scope} />
export const ContainerError: React.FC<IProps> = ({
  message = "Une erreur s'est produite",
  scope,
}) => <ErrorContainerMessage message={message} scope={scope} />

const MessageText = styled.div`
  margin: 10% 0;
  text-align: center;
`
const ErrorContainerMessage = styled(ContainerMessage)`
  color: ${({ theme }) => theme.color.pinkDark};
`
